<template>
  <Popup :width="width" :margin="margin" :radiusType="radiusType" :closePopupEvent="closePopup">
    <div class="checkout_container" slot="component">
      <div v-if="loader && !paymentSuccess">
        <Loading></Loading>
      </div>
      <div v-else-if="!loader && !paymentSuccess">
        <p class="checkout_container_title">{{ $t('credit card') }}</p>
        <form id="payment-form" method="POST" action="https://merchant.com/charge-card">
          <div class="form-group">
            <div v-if="formError" class="error-container">
              <p class="error">{{ formError }}</p>
            </div>
          </div>
          <div class="one-liner">
            <!-- <div class="card-frame card_height">
              form will be added here
            </div>-->

            <label for="card-number">{{$t('Card number')}}</label>
            <div class="input-container card-number">
              <div class="icon-container">
                <img id="icon-card-number" src="@/assets/icons/card.svg" alt="PAN" />
              </div>
              <div
                class="card-number-frame"
                :class="[localDisplayLang === 'ara' ? 'card-number-frame-ara': 'card-number-frame']"
              ></div>
              <div class="icon-container payment-method">
                <img id="logo-payment-method" />
              </div>
              <div class="icon-container">
                <img id="icon-card-number-error" src="@/assets/icons/error.svg" />
              </div>
            </div>

            <div class="date-and-code">
              <div>
                <label for="expiry-date">{{$t('Expiry date')}}</label>
                <div class="input-container" :class="localDisplayLang === 'ara' ? 'rtl-expiry-date': 'expiry-date'">
                  <div class="icon-container">
                    <img id="icon-expiry-date" src="@/assets/icons/exp-date.svg" alt="Expiry date" />
                  </div>
                  <div
                    class="expiry-date-frame"
                    :class="[localDisplayLang === 'ara' ? 'expiry-date-frame-ara': 'expiry-date-frame']"
                  ></div>
                  <div class="icon-container">
                    <img id="icon-expiry-date-error" src="@/assets/icons/error.svg" />
                  </div>
                </div>
              </div>

              <div>
                <label for="cvv">{{$t('Security code')}}</label>
                <div class="input-container cvv">
                  <div class="icon-container">
                    <img id="icon-cvv" src="@/assets/icons/cvv.svg" alt="CVV" />
                  </div>
                  <div
                    class="cvv-frame"
                    :class="[localDisplayLang === 'ara' ? 'cvv-frame-ara': 'cvv-frame']"
                  ></div>
                  <div class="icon-container">
                    <img id="icon-cvv-error" src="@/assets/icons/error.svg" />
                  </div>
                </div>
              </div>
            </div>

            <!-- add submit button -->
            <button
              class="button-primary primary-net5-button"
              @click="paynow()"
            >{{$t('SUBSCRIBE NOW')}}</button>

            <Loading class="form_loader" v-if="formLoader"></Loading>
          </div>
        </form>
      </div>
      <div v-else-if="!loader && paymentSuccess">
        <p class="payment_success">{{$t('Congratulations')}}! {{ paymentSuccessResponse }}</p>
        <div class="subscription-success-card">
          <img class="tick" src="@/assets/icons/Check1.svg" />
          <p class="plan-title">{{ checkoutDetails.paymentDetail.detail.planname }}</p>
          <p class="plan-type">{{ checkoutDetails.paymentDetail.detail.plantype }}</p>
          <p
            class="plan-interval"
          >{{ checkoutDetails.paymentDetail.detail.planinterval === 'MONTH' ? 30 : 365 }} days {{ checkoutDetails.paymentDetail.detail.plantag }} plan</p>
        </div>
      </div>
    </div>
  </Popup>
</template>

<script>
import { mapActions, mapMutations } from "vuex";
import { eventBus } from "@/eventBus";

export default {
  props: {
    closePopup: {
      type: Function
    },
    checkoutDetails: {
      type: Object
    }
  },
  data() {
    return {
      width: "30%",
      margin: "18% auto",
      radiusType: "full",
      formError: null,
      loader: true,
      formLoader: false,
      referenceData: null,
      paymentSuccess: false,
      paymentSuccessResponse: null,
      localDisplayLang: null
    };
  },
  watch: {
    formError(val) {
      if (val) {
        setTimeout(() => {
          this.formError = null;
        }, 5000);
      }
    }
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");

    //checking for change of language in local storage value
    eventBus.$on("check-local-storage", data => {
      this.localDisplayLang = data;
    });
    
    this.paymentInit();
  },
  mounted() {
    var form = document.getElementById("payment-form");

    eventBus.$on("paymentconfirmation-response", response => {
      if (response.reason) {
        this.formLoader = false;
        this.formError = response.reason;
      } else {
        if (response.redirect_url) {
          console.log("redirect_url", response.redirect_url);
          window.open(response.redirect_url, "_self");
        }
        // if (response.transactionstatus === 'SUCCESS') {
        //     this.paymentSuccess = true;
        //     this.paymentSuccessResponse = "You have successfully subscribed to a plan";
        //     this.setNewSubscription(true);
        // }
        this.formLoader = true;
      }
    });
  },
  methods: {
    ...mapActions(["paymentInitiation", "paymentConfirmation"]),
    ...mapMutations(["setNewSubscription"]),
    paymentInit() {
      let payload = {
        gatewayid: this.checkoutDetails.gatewaydetail.gatewayid,
        params: {
          amount: this.checkoutDetails.paymentDetail.detail.amount,
          currency: this.checkoutDetails.paymentDetail.detail.currency,
          devicetype: "WEB",
          transactionpurpose: this.checkoutDetails.paymentDetail
            .transactionPurpose,
          transactionmode: "CC",
          availabilityid: this.checkoutDetails.paymentDetail.detail
            .availabilityset[0],
          planid: this.checkoutDetails.paymentDetail.detail.planid
        }
      };

      this.paymentInitiation(payload)
        .then(response => {
          this.loader = false;

          setTimeout(() => {
            this.initializeFrames();
          }, 200);

          this.referenceData = response.data;
        })
        .catch(error => console.log(error));
    },
    paynow() {
      if (!Frames.isCardValid()) {
        this.formError = "Not a valid card";
      } else {
        this.formLoader = true;
      }
    },
    checkoutCallback(response) {
      let currentDomain = window.location.origin;
      let referencedata = response.referencedata;
      referencedata.referencedata.chargeid = response.checkoutToken;
      referencedata.referencedata.return_url =
        currentDomain +
        "/profile" +
        "?" +
        "planid=" +
        response.referencedata.referencedata.planid +
        "&" +
        "referenceid=" +
        response.referencedata.referenceid;

      let payload = {
        gatewayid: response.gatewayid,
        params: {
          referencedata: referencedata
        }
      };

      console.log("checkout callback payload", payload);

      eventBus.$emit("paymentConfirmation", payload);
    },
    generateLogos() {
      var logos = {};
      logos["card-number"] = {
        src: "card",
        alt: "card number logo"
      };
      logos["expiry-date"] = {
        src: "exp-date",
        alt: "expiry date logo"
      };
      logos["cvv"] = {
        src: "cvv",
        alt: "cvv logo"
      };
      return logos;
    },
    onValidationChanged(event) {
      var e = event.element;

      if (event.isValid || event.isEmpty) {
        if (e === "card-number" && !event.isEmpty) {
          this.showPaymentMethodIcon();
        }
        this.setDefaultIcon(e);
        this.clearErrorIcon(e);
        this.clearErrorMessage(e);
      } else {
        if (e === "card-number") {
          this.clearPaymentMethodIcon();
        }
        this.setDefaultErrorIcon(e);
        this.setErrorIcon(e);
        this.setErrorMessage(e);
      }
    },
    paymentMethodChanged(event) {
      var pm = event.paymentMethod;
      let container = document.querySelector(".icon-container.payment-method");

      if (!pm) {
        this.clearPaymentMethodIcon(container);
      } else {
        this.clearErrorIcon("card-number");
        this.showPaymentMethodIcon(container, pm);
      }
    },
    clearErrorMessage(el) {
      var selector = ".error-message__" + el;
      var message = document.querySelector(selector);
      message.textContent = "";
    },
    clearErrorIcon(el) {
      var logo = document.getElementById("icon-" + el + "-error");
      logo.style.removeProperty("display");
    },
    showPaymentMethodIcon(parent, pm) {
      if (parent) parent.classList.add("show");

      var logo = document.getElementById("logo-payment-method");
      if (pm) {
        var name = pm.toLowerCase();
        logo.setAttribute("src", "images/card-icons/" + name + ".svg");
        logo.setAttribute("alt", pm || "payment method");
      }
      logo.style.removeProperty("display");
    },
    clearPaymentMethodIcon(parent) {
      if (parent) parent.classList.remove("show");

      var logo = document.getElementById("logo-payment-method");
      logo.style.setProperty("display", "none");
    },
    setErrorMessage(el) {
      var selector = ".error-message__" + el;
      var message = document.querySelector(selector);
      message.textContent = errors[el];
    },
    setDefaultIcon(el) {
      var selector = "icon-" + el;
      var logo = document.getElementById(selector);
      logo.setAttribute("src", "images/card-icons/" + logos[el].src + ".svg");
      logo.setAttribute("alt", logos[el].alt);
    },
    setDefaultErrorIcon(el) {
      var selector = "icon-" + el;
      var logo = document.getElementById(selector);
      logo.setAttribute(
        "src",
        "images/card-icons/" + logos[el].src + "-error.svg"
      );
      logo.setAttribute("alt", logos[el].alt);
    },
    setErrorIcon(el) {
      var logo = document.getElementById("icon-" + el + "-error");
      logo.style.setProperty("display", "block");
    },
    onCardTokenizationFailed(error) {
      console.log("CARD_TOKENIZATION_FAILED: %o", error);
      Frames.enableSubmitForm();
    },
    initializeFrames() {
      let self = this;

      Frames.init({
        publicKey: self.checkoutDetails.gatewaydetail.gwcredential.ApiPublicKey,
        localization: {
          cardNumberPlaceholder: "Card Number",
          expiryMonthPlaceholder: "MM",
          expiryYearPlaceholder: "YY",
          cvvPlaceholder: "CVV",
        },
        style: {
          base: {
            color: "white"
          },
          valid: {
            color: "green"
          },
          invalid: {
            color: "red"
          }
        }
      });

      Frames.addEventHandler(Frames.Events.PAYMENT_METHOD_CHANGED, function(
        event
      ) {
        // this.paymentMethodChanged(event);
      });

      Frames.addEventHandler(Frames.Events.CARD_VALIDATION_CHANGED, function(
        event
      ) {
        console.log("CARD_VALIDATION_CHANGED: %o", event);
        // self.onCardTokenizationFailed();
        // let payButton = document.getElementById("pay-button");
        // payButton.disabled = !Frames.isCardValid();
      });

      Frames.addEventHandler(Frames.Events.CARD_TOKENIZED, function(event) {
        console.log("Card tokenization completed", event.token);
        self.checkoutToken = event.token;

        let payload = {
          gatewayid: self.checkoutDetails.gatewaydetail.gatewayid,
          referencedata: self.referenceData,
          checkoutToken: self.checkoutToken
        };

        self.checkoutCallback(payload);
      });

      Frames.addEventHandler(
        Frames.Events.CARD_TOKENIZATION_FAILED,
        function() {
          self.onCardTokenizationFailed();
        }
      );

      var logos = this.generateLogos();

      let form = document.getElementById("payment-form");

      form.addEventListener("submit", function(event) {
        event.preventDefault();
        Frames.submitCard();
      });
    }
  },
  components: {
    Popup: () =>
      import(/* webpackChunkName: "popup" */ "@/components/Slots/Popup.vue"),
    Loading: () =>
      import(
        /* webpackChunkName: "Loading" */ "@/components/Templates/Loading.vue"
      )
  },
  beforeDestroy() {
    eventBus.$off("paymentconfirmation-response");
    // Frames.removeAllEventHandlers();
  }
};
</script>

<style lang="scss" scoped>
@import "@/sass/_variables.scss";
@import "@/sass/_components.scss";
@import "./cardCheckout.scss"
</style>